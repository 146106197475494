// import firebase from 'firebase';

// export const initializeFirebase = () => {
//   let config = {
//     apiKey: process.env.REACT_APP_API_KEY_FIREBASE,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAINE,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
//   };

//   firebase.initializeApp(config);
// };

import { initializeApp } from "firebase/app";
import firebase from 'firebase';

export const initializeFirebase = () => {
  let config = {
  apiKey: "AIzaSyAZYyLagsnV1SIXZHRrSVeq8AcmeIgUAZ4",
  authDomain: "madatours-travel.firebaseapp.com",
  databaseURL: "https://madatours-travel-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "madatours-travel",
  storageBucket: "madatours-travel.firebasestorage.app",
  messagingSenderId: "580479433214",
  appId: "1:580479433214:web:137e8136b5a23d0e0d28af"
  };

  firebase.initializeApp(config);
};

// const app = initializeApp(initializeFirebase);