import React, { FC } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useStyles from './style';
import logo from '../../Images/logo.svg';
import GroupeDeMasque from '../../Images/GroupeDeMasque.svg';
import fblogo from '../../Images/fblogo.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
//@ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import firebase from 'firebase';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  SIGNUP_WITH_FACEBOOK,
  SIGNUP_WITH_FACEBOOKVariables,
} from '../../Graphql/User/types/SIGNUP_WITH_FACEBOOK';
import { DO_SIGNUP_WITH_FACEBOOK } from '../../Graphql/User/mutation';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import Backdrop from '../../Components/Backdrop';
import { Link } from 'react-router-dom';

const LoginWithFacebook: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const onClickSeConnecter = () => {
    history.push('/login');
  };

  const client = useApolloClient();

  const [doSignup, { loading }] = useMutation<
    SIGNUP_WITH_FACEBOOK,
    SIGNUP_WITH_FACEBOOKVariables
  >(DO_SIGNUP_WITH_FACEBOOK, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (
        data &&
        data.signupWithFacebook &&
        data.signupWithFacebook.accessToken
      ) {
        localStorage.setItem('token', data.signupWithFacebook.accessToken);
        window.location.reload();
      } else {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: "Erreur lors de l'authentification",
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      }
    },
  });

  const responseFacebook = async (response: any) => {
    const facebookCredential = firebase.auth.FacebookAuthProvider.credential(
      response.accessToken,
    );

    const credential = await firebase
      .auth()
      .signInWithCredential(facebookCredential);
    const idToken = await credential.user?.getIdToken();

    await doSignup({ variables: { idToken: idToken ? idToken : '' } });


    history.replace('/accueil');
  };

  const onClickCreateAccount = () => {
    history.push('/choiceaccount');
  };

  return (
    <Box className={classes.container}>
      <Backdrop open={loading} />
      <Box className={classes.left}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" />
        </Box>

        <Box className={classes.login}>
          <FacebookLogin
            // appId="659620618649054"
            appId="1146833856757655"
            autoLoad={false}
            fields="name,email,picture"
            scope="email,public_profile"
            callback={responseFacebook}
            render={(renderProps: any) => (
              <Button
                variant="contained"
                color="inherit"
                onClick={renderProps.onClick}
                size="large"
                style={{ backgroundColor: '#FFF' }}
                startIcon={<img src={fblogo} alt="fblogo" />}
                className={classes.btnFacebook}
                fullWidth>
                <Typography>Continuer avec Facebook</Typography>
              </Button>
            )}
          />

          <Button
            variant="outlined"
            color="primary"
            onClick={onClickSeConnecter}
            size="large"
            fullWidth>
            Se connecter
          </Button>

          <Typography>Vous n&apos;avez pas de compte ?</Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={onClickCreateAccount}
            size="large"
            fullWidth>
            Créer un compte
          </Button>
        </Box>

        <Box className={classes.terms}>
          <Typography>
            En vous connectant sur notre plateforme, <br />
            vous êtes d&apos;accord avec&nbsp;
            <Link style={{ color: 'inherit' }} to="/terms">
              les termes et les conditions
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bg}>
        <img src={GroupeDeMasque} alt="Mobix" />
      </Box>
    </Box>
  );
};

export default withRouter(LoginWithFacebook);
